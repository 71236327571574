import React, { useEffect, useState } from 'react';
import { axios } from '../../../axios'
import { useNavigate } from 'react-router-dom'
import M from 'materialize-css'
import SliderImg from './SliderImg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CarruselConfigs = () => {

	const [imgSlider, setImgsSlider] = useState({});
	const [imgs, setImgs] = useState([])
	const navigate = useNavigate()

	useEffect(()=>{

		axios.get('/api/seller/configwebsitesliders').then((response)=>{
			setImgs(response.data)
		}).catch(e=>{
			if(e.response.data.error==='uncompleted'){
				alert(e.response.data.message)
				navigate('/mis-datos')
			}else{
				alert(e)
			}
		})
	},[imgSlider])

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};
	const grid = 14;

	const getItemStyle = (isDragging, draggableStyle) => ({
	  // some basic styles to make the items look a bit nicer
	  userSelect: 'none',
	  margin: 0,
	  padding: 0,

	  // change background colour if dragging
	  background: isDragging ? 'rgba(220, 220, 220, .9)' : 'rgba(255, 255, 255, .9)',

	  // styles we need to apply on draggables
	  ...draggableStyle,
	});

	const getListStyle = isDraggingOver => ({
		background: isDraggingOver ? "#FFF9DC" : "#F3F3F3",
		padding: grid,
		width: '100%'
	});

	const onDragEnd = result =>{
		// dropped outside the list
		if (!result.destination) {
		  return;
		}
		const item = reorder(imgs,result.source.index,result.destination.index)
		setImgs(item)
		const order = item.map(i=>i.id)
		axios.post('/api/seller/reordersliders',{order:order}).then((res)=>{
			M.toast({html: 'Actualizado!'})	
		}).catch(e=>console.log("error",e))
	}

	const _pushImg = (file) =>{
		const formData = new FormData()
		formData.append('files', file)
		
		axios.post('/api/seller/setSliderImg',formData).then((r) => {
			setImgsSlider(r.data)
			M.toast({html: 'Imagen Guardada!'})			
		})
		.catch(r=>alert(r))
	}

	return (
		<div>

			<h4 style={{padding:20}}>Carrusel de Imagenes</h4>
			<h5 className="red-text" style={{padding:'0 20px', fontWeight:'700'}}>Debes tener al menos una imagen disponible para el carrusel</h5>
			<div className='row'>
				<div className='col s12'>
					<span style={{border: '4px dashed rgba(0,0,0,0.35)', color:'#9e9e9e', borderRadius:20, height:192, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', fontSize:30}}>
						<input onChange={(e)=>_pushImg(e.target.files[0])} type='file' style={{cursor:'pointer',opacity:0,width:'100%', height:'100%', display:'block', position:'absolute'}} accept="image/*"/>
						Haz click o arrastra
					</span>
				</div>
				{/**
					IMAGENES
				*/}
				<div className='col s12'>
					<p style={{color:'red'}}>Nota: Arrastre en orden que desee visualizar en la página principal</p>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
							<div className="row" style={getListStyle(snapshot.isDraggingOver)} ref={provided.innerRef} {...provided.droppableProps}>
							{imgs.map((item,index) =>
								<Draggable key={item.id} draggableId={String(item.id)} index={index}>
								{(provided, snapshot) => (
									<div className="col s12" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)} >
										<SliderImg img={item} setImgs={setImgs} image={imgs} />
									</div>
									)}
								</Draggable>
							)}
							{provided.placeholder}
							</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		</div>
	);
};

export default CarruselConfigs;
