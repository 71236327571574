import throttle from './throttle';
//import {createBrowserHistory} from 'history';
window.axios = require('axios');

window.axios.defaults.baseURL = 'https://kangoru-inventario-api.mimodamia.com';

/**
 * Interceptor para los requests, añadiendo el throttling
 */
window.axios.interceptors.request.use(
  (config) => {
    // Llamamos a throttle, si la solicitud puede proceder, retornamos la config
    return throttle(config)
      .then((resolvedConfig) => resolvedConfig)  // Devuelve la config si pasa el throttle
      .catch((error) => Promise.reject(error));  // Si está bloqueada, rechaza la solicitud
  },
  (error) => Promise.reject(error)  // Manejo de errores
);


/**
  * Interceptor para los responses
  */
window.axios.interceptors.response.use(
    function (response) { 
        return response; 
    }, 
    function (error) {
        if (401 === error.response?.status) {
            //window.location.replace('login');
           //createBrowserHistory().push('/login');
           //window.location.reload();
           window.location.replace('/login');
        }

	return Promise.reject(error);
    });

window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
export const axios = window.axios;
